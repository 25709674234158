import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../styles";
import { font } from "../../../styles/themes";

export const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    width: "100%",
  },
  head: {
    alignItems: "center",
    paddingTop: 20,
    paddingHorizontal: 20,
    paddingBottom: 8,
    width: "100%",
  },
  scroll: {
    backgroundColor: color2.WHITE,
    paddingHorizontal: dpToPx(8),
  },
  wrap: {
    flex: 1,
    width: "70%",
    marginHorizontal: "auto",
  },
  title: {
    marginTop: 20,
    fontSize: 24,
    fontFamily: font.family.montserrat,
    fontWeight: "700",
  },
  line: {
    marginTop: 15,
    marginBottom: 10,
  },
  switchView: {
    marginTop: 15,
    marginBottom: 10,
  },
  money: {
    fontSize: 14,
    color: color2.BLUE_DARK,
    fontFamily: font.family.montserrat,
    fontWeight: "500",
    position: "absolute",
    right: 10,
  },
  addressWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 25,
    alignItems: "center",
  },
  star: {
    fontSize: 15,
    fontFamily: font.family.montserrat,
    fontWeight: "500",
    color: color2.BLUE_DARK,
    letterSpacing: 3,
  },
  address: {
    fontSize: 12,
    fontFamily: font.family.montserrat,
    fontWeight: "500",
    color: color2.BLUE_DARK,
    lineHeight: 18,
    textAlign: "center",
    marginTop: 8,
  },
  submitButton: {
    marginTop: 15,
  },
  modalText: {
    textAlign: "center",
    marginBottom: 30,
  },
});
