import React, { useContext } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import HeaderLogo from "src/components/HeaderLogo";
import { RequestStatus } from "src/lib/api/types";
import { useApi } from "src/lib/ApiProvider";
import { toQueryParms } from "src/utils";

import { ReceiptsForm } from "./ReceiptsForm";
import { ReceiptFormValues } from "./types";
import { ReceiptsContext } from "src/lib/receiptsContext";
import { Navigate } from "react-router-dom";
import { prop, reverse, sortBy } from "ramda";

export const RequestReceiptsForm = () => {
  const { receiptsStatus, setReceipts, setReceiptsStatus } =
    useContext(ReceiptsContext);
  const [emailStatus, setEmailStatus] = React.useState<RequestStatus>("idle");
  const { api, authApi } = useApi();

  const handleRegisterEmail = React.useCallback(
    async (sessionToken: string, email: string) => {
      authApi
        .patch(
          `api/customer/v2/customers/me`,
          {
            email,
          },
          { headers: { authorization: `Bearer ${sessionToken}` } } // token has to be explicitely passed as it can't be taken from urlParams
        )
        .then((res) => {
          if (res.status === 200) {
            setEmailStatus("success");
          }
        })
        .catch((err) => {
          setEmailStatus("error");
          console.error(err);
        });
    },
    [authApi]
  );
  const handleSubmit = React.useCallback(
    async (values: ReceiptFormValues) => {
      setReceiptsStatus("loading");
      try {
        const response = await api.get(
          `api/receipts?${toQueryParms(
            values as unknown as Record<string, string>
          )}`
        );
        setReceipts(reverse(sortBy(prop("draftCreatedAt"), response.data)));

        if (values.email) {
          if (response.data.length > 0) {
            const sessionToken = response.data[0].sessionToken;
            const email = values.email!;
            handleRegisterEmail(sessionToken, email);
          } else {
            setEmailStatus("error");
          }
        }
        setReceiptsStatus("success");
      } catch (err) {
        setReceiptsStatus("error");
      }
    },
    [api, handleRegisterEmail, setReceipts, setReceiptsStatus]
  );
  if (receiptsStatus === "success") {
    setReceiptsStatus("idle");
    return <Navigate to="/list" replace={false} />;
  }
  return (
    <div
      style={{
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <HeaderLogo />
      <Col>
        <ReceiptsForm onSubmit={handleSubmit} emailStatus={emailStatus} />
        {receiptsStatus === "loading" && (
          <Row className="justify-content-center">
            <Spinner />
          </Row>
        )}
      </Col>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "12px"
        }}
      >
        <PoweredByAiFi />
      </div>
    </div>
  );
};
