import React, { useContext, useState } from "react";
import { MasterContext } from "src/lib/masterContext";
import { formatCurrency } from "src/lib/utils";
import { ReceiptInfo } from "../ReceiptsForm/types";
import styled from "styled-components";
import { Tooltip } from "reactstrap";
import { Icon } from "src/assets/icons/icon";

interface ReceiptsListProps {
  receipts: ReceiptInfo[];
}

const Table = styled.table`
  @media (min-width: 768px) {
    max-width: 66%;
  }
`;

const TableHeader = styled.th`
  padding: 8px;
`;

const TableCell = styled.td`
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

export const ReceiptsListTable: React.FC<ReceiptsListProps> = ({
  receipts,
}) => {
  const {
    flavor,
    appConfig: { timezone },
  } = useContext(MasterContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <Table>
      <thead>
        <tr
          style={{
            borderTop: "1px solid #8A8A8A",
            borderBottom: "1px solid #8A8A8A",
          }}
        >
          <TableHeader>Date</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader id="StatusInfoTooltip">
            Status <Icon icon="InfoIcon" />
          </TableHeader>
          <TableHeader>Total</TableHeader>
          <TableHeader></TableHeader>
        </tr>
      </thead>
      <tbody>
        {receipts.map((x) => (
          <tr key={x.id}>
            <TableCell>
              {flavor.formatTableDateTime(x.draftCreatedAt, timezone).date}
            </TableCell>
            <TableCell>
              {flavor.formatTableDateTime(x.draftCreatedAt, timezone).time}
            </TableCell>
            <TableCell style={{ textTransform: "capitalize" }}>
              {x.status}
            </TableCell>
            <TableCell>
              {x.status === "draft" ? "Pending" : formatCurrency(x.totalPrice, x.currencyCode)}
            </TableCell>
            <TableCell>
              <a
                rel="noreferrer"
                href={`/receipts/${x.id}/${x.sessionToken}`}
                target="_blank"
              >
                View
              </a>
            </TableCell>
          </tr>
        ))}
      </tbody>
      <Tooltip
        isOpen={tooltipOpen}
        target="StatusInfoTooltip"
        toggle={toggle}
        autohide={false}
        placement={"bottom"}
        style={{ textAlign: "left" }}
      >
        <ul style={{ paddingLeft: "16px", marginBottom: "4px" }}>
          <li>Draft – Order being processed by OASIS</li>
          <li>Paid – Order has been processed and paid</li>
          <li>
            Payment Processing – Order has been processed and payment is under
            process
          </li>
          <li>Unpaid - Order has been processed but payment failed</li>
          <li>
            Pending - Order has been contested by shopper and is pending
            revision by the retailer
          </li>
          <li>
            Reviewed - Order has been contested by shopper and the retailer has
            submitted the revision
          </li>
        </ul>
      </Tooltip>
    </Table>
  );
};
