import { Flavor } from "./flavor";
import { color2 } from "src/styles";

class REWEFlavor extends Flavor {
  getName() {
    return "REWE";
  }

  getFullName() {
    return "REWE";
  }

  getMainColor() {
    return color2.BOXLL_RED;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BOXLL_RED];
  }

  getDashLineColor() {
    return color2.BOXLL_RED;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "servicebox@lekkerland.com";
  }

  getAddress(): string {
    return "REWE TO GO\nLekkerland SE\nEuropaallee 57\n50226 Frechen";
  }

  getTouLink() {
    return "http://aifi.com";
  }

  getAboutUsLink() {
    return "http://aifi.com";
  }

  getAPIEndpoint() {
    return "https://oasis-api.58-38.client.aifi.io";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <></>;
  }

  getReceiptLogo() {
    return (
      <p style={{ textAlign: "center" }}>
        <b>REWE To Go</b>
        <br />
        Lekkerland SE
        <br />
        Europaallee 57
        <br />
        50226 Frechen
        <br />
        UID-Nr.: DE815866102
      </p>
    );
  }

  getFeatureFlags() {
    return {
      ...super.getFeatureFlags(),
      hidePoweredByAiFi: true,
      orderDetailsShowHeader: false,
      orderDetailsShowDetailsAtTheTop: false,
      orderDetailsShowProductsListTitle: false,
      orderDetailsShowFooterOrderId: false,
      contestingEnabled: false,
      downloadReceiptEnabled: false,
    };
  }
}

const Singleton = new REWEFlavor();
export default Singleton;
