import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoNewJerseyDevils } from "src/components/LogoNewJerseyDevils";

class NewJerseyDevils extends Flavor {
  getName() {
    return "New Jersey Devils";
  }

  getFullName() {
    return "New Jersey Devils";
  }

  getMainColor() {
    return color2.NEW_JERSEY_DEVILS;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.NEW_JERSEY_DEVILS];
  }

  getDashLineColor() {
    return color2.NEW_JERSEY_DEVILS;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getAPIEndpoint() {
    return "";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoNewJerseyDevils />;
  }
}

const Singleton = new NewJerseyDevils();
export default Singleton;
