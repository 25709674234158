import { font } from "../../../styles/themes";
import { StyleSheet } from "react-native";
import { color2, dpToPx } from "../../../styles";

export const styles = StyleSheet.create({
  wrapper: {
    padding: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: dpToPx(14),
    color: color2.BLUE_DARK,
    fontFamily: font.family.montserrat,
    fontWeight: "500",
  },
  leftText: {
    flex: 2,
    textAlign: "left",
    marginRight: dpToPx(8),
  },
  rightText: {
    textAlign: "right",
    marginLeft: dpToPx(5),
  },
});
