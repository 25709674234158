import { color2 } from "../../styles";
import { timezoneDate } from "../utils";
import { Flavor } from "./flavor";

class AiFiFlavor extends Flavor {
  getName() {
    return "AiFi";
  }

  getFullName() {
    return "AiFi Inc.";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.LIGHT_BLUE];
  }

  getDashLineColor() {
    return color2.LIGHT_BLUE2;
  }

  getAPIEndpoint() {
    return "";
  }

  formatTableDateTime(dateTime: string, timezone: string): { date: string; time: string } {
    const date = timezoneDate(dateTime, timezone)
    const formatDate = "M/DD/YY";
    const formatTime = "hh:mma";

    return {
      date: date.format(formatDate),
      time: date.format(formatTime),
    };
  }

  getStripePublicKey() {
    return "pk_test_51ILX3HDSugCw1CsrdGMZYeBmb5xxpi9TYqP7srrQ65SeNb8HsGxZwZIcYUCcEN0B7lemHcDjLUXfaq6JShG3pAou007swfkbxy";
  }
}

const Singleton = new AiFiFlavor();
export default Singleton;
