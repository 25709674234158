import { useFormik } from "formik";
import React, { PropsWithChildren, useContext, useRef } from "react";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { translate } from "src/lib/i18n";
import styled from "styled-components";
import { CardType, ReceiptFormValues } from "./types";

import { loginPageSchema } from "./utils";
import { RequestStatus } from "src/lib/api/types";
import { config } from "src/lib/config";
import { Icon } from "src/assets/icons/icon";
import { MasterContext } from "src/lib/masterContext";
import Card from "src/assets/icons/Card";
import { ApplePayInstruction, GooglePayInstructions } from "../Instructions";
import { DashLine } from "src/components/v2/Dashline";

export const ErrorMessage = styled.div`
  color: #f02849;
  text-align: left;
`;

export const SuccessMessage = styled.div`
  color: #43c9ba;
  text-align: left;
`;

export const WarningMessage = styled.div`
  color: #e9741b;
  text-align: left;
`;

const RequiredLabel = styled(Label)`
  &::after {
    content: "*";
    color: red;
    display: inline;
  }
`;

interface ReceiptsFormProps {
  emailStatus: RequestStatus;
  onSubmit: (values: ReceiptFormValues) => void;
}

export const ReceiptsForm: React.FC<PropsWithChildren<ReceiptsFormProps>> = ({
  emailStatus,
  onSubmit,
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { flavor } = useContext(MasterContext);

  const receiptsForm = useFormik({
    validateOnMount: true,
    initialValues: {
      customerPaymentInstrumentLast4: "",
      customerPaymentInstrumentExpDate: "",
      visitDate: "",
      captchaToken: "",
      email: "",
    },
    validationSchema: loginPageSchema,
    onSubmit: (args: ReceiptFormValues) => {
      onSubmit(args);
      recaptchaRef?.current?.reset();
    },
  });

  const getLast4Message = (cardType: CardType | undefined): string => {
    switch (cardType) {
      case "apple":
        return translate("receiptsHome.last4Apple");
      case "google":
        return translate("receiptsHome.last4Google");
      case "card":
      default:
        return translate("receiptsHome.last4");
    }
  };

  return (
    <div style={{ fontWeight: 500 }}>
      <Row className="justify-content-center">
        <Col sm={7} style={{ textAlign: "center" }}>
          <h2 className="mb-3">
            <b>Receipt Portal</b>
          </h2>
          {!receiptsForm.values.cardType && (
            <>
              <DashLine />
              <h5 className="my-3">Select the payment type that you used</h5>
            </>
          )}
        </Col>
      </Row>
      <Form
        className="row justify-content-center"
        onSubmit={receiptsForm.handleSubmit}
      >
        <FormGroup row className="justify-content-center">
          <Col sm={7}>
            <ButtonGroup style={{ width: "100%", maxHeight: "68px" }}>
              <Button
                style={{ width: "33%", display: 'flex' }}
                outline={receiptsForm.values.cardType !== "card"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "card");
                }}
              >
                <Card
                  color={
                    receiptsForm.values.cardType === "card"
                      ? "white"
                      : flavor.getMainColor()
                  }
                />
              </Button>
              <Button
                style={{ width: "33%" }}
                outline={receiptsForm.values.cardType !== "apple"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "apple");
                }}
              >
                <Icon
                  icon="ApplePayIcon"
                  style={{ width: "100%", height: "100%", filter: receiptsForm.values.cardType === "apple" ? 'invert()' : '' }}
                />
              </Button>
              <Button
                style={{ width: "33%" }}
                outline={receiptsForm.values.cardType !== "google"}
                onClick={() => {
                  receiptsForm.setFieldValue("cardType", "google");
                }}
              >
                <Icon
                  icon="GooglePayIcon"
                  style={{ width: "100%", height: "100%" }}
                />
              </Button>
            </ButtonGroup>
          </Col>
        </FormGroup>
        {receiptsForm.values.cardType && (
          <>
            {receiptsForm.values.cardType === "apple" && (
              <ApplePayInstruction />
            )}
            {receiptsForm.values.cardType === "google" && (
              <GooglePayInstructions />
            )}
            <FormGroup row className="justify-content-center">
              <RequiredLabel for="input-cc-last-digits" sm={3} className="fw-bolder">
                {getLast4Message(receiptsForm.values.cardType)}
              </RequiredLabel>
              <Col sm={4}>
                <Input
                  id="input-cc-last-digits"
                  pattern="\d\d\d\d"
                  name="customerPaymentInstrumentLast4"
                  placeholder="e.g. 1234"
                  value={receiptsForm.values.customerPaymentInstrumentLast4}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (value.length < 5) {
                      receiptsForm.handleChange(e);
                    }
                  }}
                  onBlur={receiptsForm.handleBlur}
                  maxLength={4}
                />
                {receiptsForm.errors.customerPaymentInstrumentLast4 &&
                receiptsForm.touched.customerPaymentInstrumentLast4 ? (
                  <ErrorMessage>
                    {receiptsForm.errors.customerPaymentInstrumentLast4}
                  </ErrorMessage>
                ) : null}
              </Col>
            </FormGroup>
            {receiptsForm.values.cardType === "card" && (
              <FormGroup row className="justify-content-center">
                <RequiredLabel for="input-exp-date" sm={3} className="fw-bolder">
                  {translate("receiptsHome.expDate")}
                </RequiredLabel>
                <Col sm={4}>
                  <Input
                    id="input-exp-date"
                    name="customerPaymentInstrumentExpDate"
                    placeholder="MM/YY"
                    pattern="\d\d/\d\d"
                    value={receiptsForm.values.customerPaymentInstrumentExpDate}
                    onChange={receiptsForm.handleChange}
                    onBlur={receiptsForm.handleBlur}
                    mask="99/99"
                    tag={InputMask}
                  />
                  {receiptsForm.errors.customerPaymentInstrumentExpDate &&
                  receiptsForm.touched.customerPaymentInstrumentExpDate ? (
                    <ErrorMessage>
                      {receiptsForm.errors.customerPaymentInstrumentExpDate}
                    </ErrorMessage>
                  ) : null}
                </Col>
              </FormGroup>
            )}
            <FormGroup row className="justify-content-center">
              <RequiredLabel for="input-visit-date" sm={3} className="fw-bolder">
                {translate("receiptsHome.visitDate")}
              </RequiredLabel>
              <Col sm={4}>
                <Input
                  id="input-visit-date"
                  name="visitDate"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  value={receiptsForm.values.visitDate}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                />
                {receiptsForm.errors.visitDate &&
                receiptsForm.touched.visitDate ? (
                  <ErrorMessage>{receiptsForm.errors.visitDate}</ErrorMessage>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row className="justify-content-center">
              <Label for="input-show-email" sm={3} className="fw-bolder">
                {translate("receiptsHome.registerEmail")}
              </Label>
              <Col sm={4}>
                <Input
                  id="input-email"
                  name="email"
                  type="email"
                  value={receiptsForm.values.email}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                  placeholder="Optional"
                />
                {receiptsForm.errors.email && receiptsForm.touched.email ? (
                  <ErrorMessage>{receiptsForm.errors.email}</ErrorMessage>
                ) : emailStatus === "success" ? (
                  <SuccessMessage>
                    {translate("receiptsHome.registerEmailSuccess")}
                  </SuccessMessage>
                ) : emailStatus === "error" ? (
                  <WarningMessage>
                    {translate("receiptsHome.registerEmailFail")}
                  </WarningMessage>
                ) : null}
              </Col>
            </FormGroup>
            <Row className="justify-content-center">
              <Col sm={7} md={7}>
                {children}
              </Col>
            </Row>
            {receiptsForm.values.captchaToken ? (
              <Row className="justify-content-center">
                <Col sm={3} md={7} style={{ padding: "20px 0" }}>
                  <Row>
                    <Button
                      type="submit"
                      disabled={!receiptsForm.isValid}
                      title={translate("receiptsHome.submit")}
                    >
                      {translate("receiptsHome.submit")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center w-auto">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.RECAPTCHA_KEY}
                  onChange={(token: string | null) => {
                    receiptsForm.setFieldValue("captchaToken", token);
                  }}
                />
              </Row>
            )}
          </>
        )}
      </Form>
    </div>
  );
};
