import { color2 } from "../../styles";
import { Flavor } from "./flavor";
import { LogoPhiladelphiaEagles } from "src/components/LogoPhiladelphiaEagles";

class PhiladelphiaEagles extends Flavor {
  getName() {
    return "Philadelphia Eagles";
  }

  getFullName() {
    return "Philadelphia Eagles";
  }

  getMainColor() {
    return color2.PHILADELPHIA_EAGLES;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.PHILADELPHIA_EAGLES];
  }

  getDashLineColor() {
    return color2.PHILADELPHIA_EAGLES;
  }

  getSupportEmail() {
    return "support@aifi.com";
  }

  getCircleLogo() {
    return <LogoPhiladelphiaEagles />;
  }

  getAPIEndpoint() {
    return ''
  }

  getStripePublicKey() {
    return ''
  }
}

const Singleton = new PhiladelphiaEagles();
export default Singleton;
