import React, { useContext } from "react";
import { Col, Row } from "reactstrap";
import { PoweredByAiFi } from "src/assets/icons/PoweredByAiFi";
import HeaderLogo from "src/components/HeaderLogo";

import { ReceiptResult } from "./ReceiptsResult";
import { isNil } from "ramda";
import { Navigate } from "react-router-dom";
import { ReceiptsContext } from "src/lib/receiptsContext";

export const ReceiptsList = () => {
  const { receipts } = useContext(ReceiptsContext);

  if (isNil(receipts)) {
    return <Navigate to="/" />;
  }
  return (
    <Col
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "inherit",
      }}
    >
      <HeaderLogo />
      <Row className="mx-2 text-center">
        <h2>
          <b>Receipt Portal</b>
        </h2>
      </Row>
      <Row className="mx-2 text-center overflow-auto justify-content-center">
        <ReceiptResult receipts={receipts} />
      </Row>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "12px",
        }}
      >
        <PoweredByAiFi />
      </div>
    </Col>
  );
};
