import { Flavor } from "./flavor";
import { color2 } from "src/styles";
import { LogoFloridaPanthers } from "src/components/LogoFloridaPanthers";

class FloridaPanthers extends Flavor {
  getName() {
    return "Florida Panthers";
  }

  getFullName() {
    return "Florida Panthers";
  }

  getMainColor() {
    return color2.BLUE_AIFI;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BLUE_AIFI];
  }

  getDashLineColor() {
    return color2.BLUE_AIFI;
  }

  getTelephone() {
    return "";
  }

  getSupportEmail() {
    return "support@aifi.io";
  }

  getAddress() {
    return "";
  }

  getAPIEndpoint() {
    return "";
  }

  getStripePublicKey() {
    return "";
  }

  getCircleLogo() {
    return <LogoFloridaPanthers />;
  }
}

const Singleton = new FloridaPanthers();
export default Singleton;
